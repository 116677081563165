import { initializeApp } from 'firebase/app';

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: 'AIzaSyC3B-e8u5BX7GjY8Rs0R0Gu-myxSWbQRns',
  authDomain: 'itify-customshirt.firebaseapp.com',
  projectId: 'itify-customshirt',
  storageBucket: 'itify-customshirt.appspot.com',
  messagingSenderId: '516214952089',
  // appId: '1:1020787758603:web:7c9987bc90241d3e25d90e',
  measurementId: '',
};

// const firebaseConfig = {
//   apiKey: 'AIzaSyDLmE2D3f2yZpgogN5eIyp1_PhYBRIcjC8',
//   authDomain: 'fir-auth-abe80.firebaseapp.com',
//   projectId: 'fir-auth-abe80',
//   storageBucket: 'fir-auth-abe80.appspot.com',
//   messagingSenderId: '852815129011',
//   appId: '1:1020787758603:web:7c9987bc90241d3e25d90e',
//   measurementId: 'G-2T9FS3GGQF',
// };

// const firebaseConfig = {
//   apiKey: '9fa62c51a5472753872eb27dae1848ab',
//   authDomain: 'https://fir-auth-abe80.firebaseapp.com/__/auth/handler',
//   projectId: 'vue3-social-login-practice12',
//   storageBucket: 'vue3-social-login-practice12.appspot.com',
//   messagingSenderId: '1020787758603',
//   appId: '1:1020787758603:web:7c9987bc90241d3e25d90e',
//   measurementId: 'G-2T9FS3GGQF',
// };

const firebaseApp = initializeApp(firebaseConfig);

export default firebaseApp;
